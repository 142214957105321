 
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
})

 
export class LogoutComponent {

    constructor(private authService: AuthService, private alertService: AlertService, private router: Router)
    {
        this.authService.logout();
        this.router.navigate(['/login'])
    }
 
}

