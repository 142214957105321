import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginOComponent {

    public loginForm: FormGroup;
    private _authService: any;
    returnUrl: string;

    constructor(private formBuilder: FormBuilder, private authService: AuthService, private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute) {

        // fa il redirect alla home se già loggato
        if (this.authService.isLoggedIn) {
            this.router.navigate(['/pages/doc-attivi/elenco']);
        }

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/pages/doc-attivi/elenco';

        this._authService = authService;
        this.loginForm = formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }


    public onSubmit() {
        this._authService.login(this.loginForm.value.username,
            this.loginForm.value.password)
            .pipe(first())
            .subscribe(
                result => {
                    console.log(result);
                },
                error => {
                    console.log(error);
                //    this.alertService.error("Credenziali non valide!");
                },
                () => {
                    this.router.navigate(['/pages/doc-attivi/elenco']);
                }
        );
    }

    logout() {

        this._authService.logout();
    }
}

