import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void > = new Subject<void >();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

    userMenu = [ /*{ title: 'Profile' }, */{ title: 'Esci'} ];
    _menuService: NbMenuService;
    _router: Router;
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
      private breakpointService: NbMediaBreakpointsService, private authService: NbAuthService,
      private router: Router) {
      this._menuService = menuService;
      this._router = router;
      console.log("sasssssssssssssssssssssss");
      this._menuService.onItemClick()

          .subscribe((event) => {
              this.onContecxtItemSelection(event.item.title);

              this.authService.onTokenChange()
                  .subscribe((token: NbAuthJWTToken) => {
                      if (token.isValid()) {
                          console.log(token.getPayload())
                          this.user = token.getPayload();
                      }
                  });
          });

  }
    onContecxtItemSelection(title) {
        if (title == 'Esci')
            this.router.navigate(['/logout']);
    }
  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;


    this.userService.getUsers()
        .pipe(takeUntil(this.destroy$))
        .subscribe((users: any) => { this.user = users.nick; });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
