import { Observable } from 'rxjs';

export interface DocAttivi {
  name: string;
  data: string;
}

export abstract class DocAttiviData {

    abstract getDocsAttivi(token: any, currentPage: any, startDate: string, endDate: string): Observable<any>;
    abstract getDocAttivi(token: any, filename: any, type: any): Observable<any>;
    abstract viewDocAttivi(token: any, filename: any, type: any): Observable<any>;
    abstract getNotification(token: any, filename: any): Observable<any>;
    abstract downloadDocsAttivi(listaDocumentiAttivi: any, email: string,
        oggetto: string, dataStart: string, dataEnd: string): Observable<any>;

}
