import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { DocPassiviData } from '../../@core/data/doc-passivi';
import { DocAttiviData } from '../../@core/data/doc-attivi';
import { DataService } from '../../services/data.service';

@Component({
    template: `<div style="display: flex;  align-items: center; justify-content: center;">
  <button (click)="onCellClick()" style="margin-right:5px;">
    <img src="../../../assets/images/view-details-32.png" height="12" style="margin-right:4px;">DETTAGLI
  </button>
</div>`,
})
export class XmlDettaglioDocComponent implements ViewCell, OnInit {
    renderValue: string;
    dataStart: string;
    dataEnd: string;



    @Input() value: string | number;
    @Input() rowData: any;

    @Output() updateResult = new EventEmitter<any>();

    token: any;
    xmlFile: any;
    nomeFile: any;
    constructor(
        private router: Router,
        private http: HttpClient, private docAttiviService: DocAttiviData,
        private dataService: DataService
    ) {

    }

    onClick(event) {
        this.updateResult.emit(this.rowData);
    }

    ngOnInit() {
        this.renderValue = this.value.toString();
    }

    onCellClick() {
  //      this.updateResult.emit(this.rowData);
        this.dataService.data = this.rowData;

        this.router.navigate([`pages/doc-attivi/dettaglio/` + this.renderValue]);



    }
}
