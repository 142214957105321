import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="">© 2019 GENIOSOFT. GENIOSOFT S.R.L. | P. IVA 05028190659 TUTTI I DIRITTI RISERVATI.</span>`,

  /*
   *    <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
   * */

})
export class FooterComponent {
}


