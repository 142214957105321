import { Observable } from 'rxjs';

export interface DocPassivi {
    name: string;
    data: string;
}

export abstract class DocPassiviData {

    abstract getDocsPassivi(token: any, currentPage: any, startDate: string, endDate: string): Observable<any>;
    abstract getDocPassivi(token: any, filename: any, type: any): Observable<any>;
    abstract viewDocPassivi(token: any, filename: any, type: any): Observable<any>;
    abstract downloadDocsPassivi(listaDocumentiPassivi: any, email: string,
                                oggetto: string, dataStart: string, dataEnd: string): Observable<any>;
      
}
