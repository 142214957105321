import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, NgModule } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { DocAttiviData } from '../../@core/data/doc-attivi';
import { NbDialogService } from '@nebular/theme';
import {
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule, NbIconModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbUserModule,
    NbTreeGridModule,
    NbWindowModule
} from '@nebular/theme';

@NgModule({
    imports: [
        NbCardModule,
        NbTreeGridModule,
        NbIconModule,
        NbInputModule,
        NbWindowModule,
        NbActionsModule,
        NbButtonModule,
        NbRadioModule,
        NbSelectModule,
        NbUserModule,
        NbCheckboxModule,
        NbDatepickerModule,
    ]
})
@Component({
    templateUrl: './xml-download.component.html'
})

export class XmlDownloadComponent implements ViewCell, OnInit {
    renderValue: string;

    @Input() value: string | number;
    @Input() rowData: any;
    token: any;
    xmlFile: any;
    nomeFile: any;
    constructor(
        private router: Router,
        private http: HttpClient, private docAttiviService: DocAttiviData,
        private dialogService: NbDialogService
    ) {

    }

    @Output() save: EventEmitter<any> = new EventEmitter();



    onClick() {
        alert('id row ' + this.rowData.id)
    }

    @Output() updateResult = new EventEmitter<any>();

    ngOnInit() {
        this.renderValue = this.value.toString();

    }
    onCellClick(type: any) {

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
        //const USERNAME = 'FEP.GENIOS19';
        //const PASSWORD = 'AntCar99!';
        //const GRANTTYPE = 'password';
        const USERNAME = '';
        const PASSWORD = '';
        const GRANTTYPE = '';
        const payload = new HttpParams()
            .set('grant_type', GRANTTYPE)
            .set('username', USERNAME)
            .set('password', PASSWORD)

        const requestOptions = { headers: headers };
        this.http.post('https://m.geniosoft.vip/admin/arubarestserver/login', payload, requestOptions)
            .subscribe(
                res => {
                    //      console.log(res);
                    //   let response = JSON.parse(JSON.stringify(res));
                    this.token = res;
                },
                err => {
                    console.log(err.message);
                },
                () => {
                    this.docAttiviService.getDocAttivi(this.token, this.renderValue, type)
                        .subscribe(
                            (xml) => {
                                this.xmlFile = xml[0].file;
                                this.nomeFile = xml[0].filename;

                            },
                            error => console.log('error', error),
                            () => {

                                if (window.navigator && window.navigator.msSaveBlob) {
                                    var blob = new Blob([atob(this.xmlFile)], { type: 'text/xml;charset=UTF-8' });
                                    window.navigator.msSaveBlob(blob, this.nomeFile);

                                } else {
                                    const linkSource = 'data:text/xml;base64,' + this.xmlFile;
                                    const downloadLink = document.createElement("a");
                                    const fileName = this.nomeFile;
                                    downloadLink.href = linkSource;
                                    downloadLink.download = fileName;
                                    downloadLink.click();
                                }
                             });
                }
            )
    }

    onCellClickViewDoc(type: any = 'sdi') {

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
        const USERNAME = 'FEP.GENIOS19';
        const PASSWORD = 'AntCar99!';
        const GRANTTYPE = 'password';
        //const payload = new HttpParams()
        //    .set('grant_type', GRANTTYPE)
        //    .set('username', USERNAME)
        //    .set('password', PASSWORD)
        const payload = new HttpParams()
            .set('grant_type', '')
            .set('username', '')
            .set('password', '')



        const requestOptions = { headers: headers };
        this.http.post('https://m.geniosoft.vip/admin/arubarestserver/login', payload, requestOptions)
            .subscribe(
                res => {
                    //      console.log(res);
                    //   let response = JSON.parse(JSON.stringify(res));
                    this.token = res;
                },
                err => {
                    console.log(err.message);
                },
                () => {
                    this.docAttiviService.viewDocAttivi(this.token, this.renderValue, type)
                        .subscribe(
                            (xml) => {
                                this.xmlFile = xml[0].file;
                                this.nomeFile = xml[0].filename;

                            },
                            error => console.log('error', error),
                            () => {
                             //   const linkSource = 'data:application/xml;base64,' + this.xmlFile;


                                const linkSource = this.xmlFile;

                                const downloadLink = document.createElement("a");
                                const fileName = this.nomeFile;
                             /*   downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();data:text/plain;base64
                                */
                                this.updateResult.emit(linkSource);
                             //   console.log(this.xmlFile);
                            });
                }
            )


    }


}
