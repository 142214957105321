import { Injectable, Input } from '@angular/core';
import * as moment from "moment";
import {HttpClient} from '@angular/common/http';
import {tap, catchError} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as CONFIG from './config';



@Injectable({
  providedIn: 'root'
})


export class AuthService {

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) { }

    login(email: string, password: string) {
        const op = "loginFatturazione";
        const url = `${CONFIG.apiUrl}/${op}`;
        var body = { 'email': email, 'password': password };
        return this.http.post<any>(url, body).pipe(tap(res => this.setSession(res)));
    }


private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {
    console.error(error);
    return of(result as T);
  };
}

private removeSessionOperatore() {
  localStorage.removeItem("id_operatore");
  localStorage.removeItem("id_magazzino");
}


private setSession(authResult) {
  const expiresAt = moment().add(authResult.expiresIn, 'second');
  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem('subject', authResult.subject);
  localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
}


private setSessionOperatore(authResult) {
  localStorage.setItem('id_operatore', authResult.id_operatore);
  localStorage.setItem('id_magazzino', authResult.id_magazzino);
}


logout() {
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem('subject');
  localStorage.removeItem("id_operatore");
  localStorage.removeItem('id_magazzino');
}

public isLoggedIn() {
  return moment().isBefore(this.getExpiration());
}

isLoggedOut() {
  return !this.isLoggedIn();
}

getExpiration() {
  const expiration = localStorage.getItem("expires_at");
  const expiresAt = JSON.parse(expiration);
  return moment(expiresAt);
}
}
