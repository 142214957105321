import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
//import { ToastService } from  '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService , private router: Router,
        private route: ActivatedRoute/*, private toastService: ToastService*/) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),

            catchError((error: HttpErrorResponse) => {
              if (error.status === 401)
                  this.authService.logout();
               else if(error.status === 403)   {
         //       this.toastService.error("Operatore non valido");
               }
                else {
           //     this.toastService.error("Si è verificato un errore di rete! Riprovare più tardi.");
                return throwError(error);
              }
            })
          );
    }
}
