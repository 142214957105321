import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { DocPassiviData } from '../../@core/data/doc-passivi';

/*
  <button (click)="onCellClick('p7m')" style="margin-right:5px;">
    <img src="../../../assets/images/download-2-32.png" height="12" style="margin-right:4px;">P7M
  </button>
 * */
@Component({
    template: `<div style="display: flex;  align-items: center; justify-content: center;">
  <button (click)="onCellClick('xml')" style="margin-right:5px;">
    <img src="../../../assets/images/download-2-32.png" height="12" style="margin-right:4px;">XML
  </button>
  <button (click)="onCellClickViewDoc('sdi')" style="margin-right:5px;">
    <img src="../../../assets/images/view-details-32.png" height="12" style="margin-right:4px;">Vers.SDI
  </button>
  <button (click)="onCellClickViewDoc('sem')" style="margin-right:5px;">
    <img src="../../../assets/images/view-details-32.png" height="12" style="margin-right:4px;">Vers. Sempl.
  </button>
</div>
`,
})
export class XmlDownloadPComponent implements ViewCell, OnInit {
    renderValue: string;

    @Input() value: string | number;
    @Input() rowData: any;

    @Output() updateResult = new EventEmitter<any>();

    token: any;
    xmlFile: any;
    nomeFile: any;
    constructor(
        private router: Router,
        private http: HttpClient, private docPassiviService: DocPassiviData
    ) {

    }

    ngOnInit() {
        this.renderValue = this.value.toString();
    }

    onCellClick(type: any) {

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
        const USERNAME = '';
        const PASSWORD = '';
        const GRANTTYPE = '';
        const payload = new HttpParams()
            .set('grant_type', GRANTTYPE)
            .set('username', USERNAME)
            .set('password', PASSWORD)

        const requestOptions = { headers: headers };
        this.http.post('https://m.geniosoft.vip/admin/arubarestserver/login', payload, requestOptions)
            .subscribe(
                res => {
                    //      console.log(res);
                    //   let response = JSON.parse(JSON.stringify(res));
                    this.token = res;
                },
                err => {
                    console.log(err.message);
                },
                () => {
                    this.docPassiviService.getDocPassivi(this.token, this.renderValue, type)
                        .subscribe(
                            (xml) => {
                                console.log(xml)
                                this.xmlFile = xml[0].file;
                                this.nomeFile = xml[0].filename;
                            },
                            error => console.log('error', error),
                            () => {
                                if (window.navigator && window.navigator.msSaveBlob) {
                                     var blob = new Blob([atob(this.xmlFile)], { type: 'text/xml;charset=UTF-8' });
                                    window.navigator.msSaveBlob(blob, this.nomeFile);

                                } else {
                                    const linkSource = 'data:text/xml;base64,' + this.xmlFile;
                                    const downloadLink = document.createElement("a");
                                    const fileName = this.nomeFile;
                                   downloadLink.href = linkSource;
                                   downloadLink.target = '_blank';
                                   downloadLink.download = fileName;
                                   downloadLink.click();
                                }
                             });
                }
            )
    }

    onCellClickViewDoc(type: any = 'sdi') {

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
        const USERNAME = 'FEP.GENIOS19';
        const PASSWORD = 'AntCar99!';
        const GRANTTYPE = 'password';
        const payload = new HttpParams()
            .set('grant_type', GRANTTYPE)
            .set('username', USERNAME)
            .set('password', PASSWORD)

        const requestOptions = { headers: headers };
        this.http.post('https://m.geniosoft.vip/admin/arubarestserver/login', payload, requestOptions)
            .subscribe(
                res => {
                    //      console.log(res);
                    //   let response = JSON.parse(JSON.stringify(res));
                    this.token = res;
                },
                err => {
                    console.log(err.message);
                },
                () => {
                    this.docPassiviService.viewDocPassivi(this.token, this.renderValue, type)
                        .subscribe(
                            (xml) => {
                                this.xmlFile = xml[0].file;
                                this.nomeFile = xml[0].filename;

                            },
                            error => console.log('error', error),
                            () => {
                               // const linkSource = 'data:application/xml;base64,' + this.xmlFile;


                                const linkSource = this.xmlFile;
                                const downloadLink = document.createElement("a");
                                const fileName = this.nomeFile;
                                /*   downloadLink.href = linkSource;
                                   downloadLink.download = fileName;
                                   downloadLink.click();data:text/plain;base64
                                   */
                                this.updateResult.emit(linkSource);
                                //   console.log(this.xmlFile);
                            });
                }
            )


    }
}
