import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { XmlDownloadComponent } from './pages/xml-download/xml-download.component';
import { XmlDownloadPComponent } from './pages/xml-download-p/xml-download-p.component';
import { XmlDettaglioDocComponent } from './pages/xml-dettaglio-doc/xml-dettaglio-doc.component';
import { LoginOComponent } from './pages/login_o/login.component';
import { AuthGuardO } from './guards/auth-guard-o.service';
import { LogoutComponent } from './pages/login_o/logout.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginOComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
            .then(m => m.PagesModule),
           canActivate: [AuthGuardO]
    },
    // {
    //    path: 'auth',
    //    loadChildren: './auth/auth.module#NgxAuthModule',
    // },


    {
        path: 'xml',
        component: XmlDownloadComponent,
    },

    {
        path: 'xmlp',
        component: XmlDownloadPComponent,
    },
    {
        path: 'xmldettagliodoc',
        component: XmlDettaglioDocComponent,
    },
  /* {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  */

  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
    useHash: false,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

