import { Injectable } from '@angular/core';
import { of as observableOf, Observable, of } from 'rxjs';
import { DocAttiviData } from '../data/doc-attivi';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const URL_WS = 'https://m.geniosoft.vip/admin/arubarestserver/invoice_out_findByUsername';


  const USERNAME = 'FEP.GENIOS19';
  const PASSWORD = 'AntCar99!';
// const USERNAME = 'PREMIUM00000000398';
// const PASSWORD = 'AntCar99!';
@Injectable()

export class DocAttiviService extends DocAttiviData {

    constructor(private http: HttpClient) {
        super ();
    }

    getDocsAttivi(token: any, currentPage: number, startDate: string, endDate: string): Observable<any> {
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token
        });
     //   const piva = "03779110653";
      //  const piva = "00000000157";
        const piva = localStorage.getItem('subject');

        const op = "?size=100&page=" + currentPage + "&username=" + USERNAME + "&countrySender=IT&vatcodeSender=" + piva + "&token=" + token + "&startDate=" + startDate + "&endDate=" + endDate;

     //   const op = "/services/invoice/out/findByUsername?size=100&page=" + currentPage + "&username=" + USERNAME +"&countrySender=IT&vatcodeSender="+piva;
       const url = `${URL_WS}/${op}`;
     /*   return this.http.get<any[]>(url, { headers: reqHeader }).pipe(
            tap(_ => console.log(`fetched findByUsername piva=${piva}`)),
            catchError(this.handleError<any>(`findByUsername piva=${piva}`))
        );
        */
        return this.http.get<any[]>(url).pipe(
            tap(_ => console.log(`fetched findByUsername piva=${piva}`)),
            catchError(this.handleError<any>(`findByUsername piva=${piva}`))
        );
    }

 
    getDocAttivi(token: any, filename: any, type: any): Observable<any> {

        const URL_WS = 'https://m.geniosoft.vip/admin/arubarestserver/invoice_xml_out_getByFilename';
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token
        });
        const op = "?filename=" + filename + "&token=" + token + "&type=" + type;
        const url = `${URL_WS}/${op}`;
        return this.http.get<any[]>(url, /* { headers: reqHeader }*/).pipe(
            tap(_ => console.log(`fetched findByUsername piva=${filename}`)),
            catchError(this.handleError<any>(`findByUsername piva=${filename}`))
        );
    }

    viewDocAttivi(token: any, filename: any, type: any): Observable<any> {

        const URL_WS = 'https://m.geniosoft.vip/admin/arubarestserver/viewDocAttiviSdi';
  /*      let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token
        });
        */
        const op = "?filename=" + filename + "&token=" + token + "&type=" + type;
        const url = `${URL_WS}/${op}`;
        return this.http.get<any[]>(url, /* { headers: reqHeader }*/).pipe(
            tap(_ => console.log(`fetched findByUsername piva=${filename}`)),
            catchError(this.handleError<any>(`findByUsername piva=${filename}`))
        );
    }


    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }

     getNotification(token: any, filename: any): Observable<any> {

        const URL_WS = 'https://m.geniosoft.vip/admin/arubarestserver/notification_out_getByInvoiceFilename';

        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token
        });
        const op = "?filename=" + filename + "&token=" + token;
        const url = `${URL_WS}/${op}`;
        return this.http.get<any[]>(url).pipe(
            tap(_ => console.log(`fetched getNotification piva=${filename}`)),
            catchError(this.handleError<any>(`getNotification piva=${filename}`))
        );
     }

     

    downloadDocsAttivi(listaDocumentiAttivi: any, email: string, oggetto: string,
        dataStart: string, dataEnd: string): Observable<any> {

        const params = new HttpParams()
            .set('email', email)
            .set('oggetto', oggetto)
            .set('dataStart', dataStart)
            .set('dataEnd', dataEnd);


        const URL_WS = 'https://m.geniosoft.vip/admin/arubarestserver/downloadDocumentiAttivi';

        const op = '';
        const url = `${URL_WS}/${op}`;
        return this.http.post<any[]>(url, listaDocumentiAttivi, { params }).pipe(
            tap(_ => console.log(`fetched downloadDocumentiAttivi piva=}`)),
            catchError(this.handleError<any>(`downloadDocumentiAttivi piva=`))
        );
    }


    //  getNotification(token: any, filename: any): Observable<any> {

    //    const URL_WS = 'https://m.geniosoft.vip/admin/arubarestserver/notification_out_getByInvoiceFilename';

    //    let reqHeader = new HttpHeaders({
    //        'Content-Type': 'application/json;charset=UTF-8',
    //        'Authorization': 'Bearer ' + token
    //    });
    //      var body = {'filename': filename, 'token' : token};
    ////    const op = "?filename=" + filename + "&token=" + token;
    //      const op = '';
    //    const url = `${URL_WS}/${op}`;
    //      return this.http.post<any[]>(url, body, /* { headers: reqHeader }*/).pipe(
    //        tap(_ => console.log(`fetched getNotification piva=${filename}`)),
    //        catchError(this.handleError<any>(`getNotification piva=${filename}`))
    //    );
    // }
}

